:root{
    --bars: rgba(20, 70, 104, 0.801);
    --text: rgb(191, 197, 190);
}
h1,h2,h3,h4,h5,p,label{
    color: var(--text);
}
p{
    font-size: large;
}

#root{
    background: url('./assets/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 22%;
    min-height: 100vh;
}
header{
    background-color: var(--bars);
    height: 12vh;
}
.headshot{
    height: 100%;
}
.title{
    margin-left: 1em;
    color: var(--text);
}
.nav{
    align-items: center;
}
.nav-item{
    font-size: xx-large;
}
#about,#portfolio,#resume,#contact{
    color: var(--text);
}
#about:hover,#portfolio:hover,#resume:hover,#contact:hover{
    color: white;
}
#aboutMeHead{
    height: 150px;
    border-radius: 100%;
}
#aboutHeader{
    margin-left: 15px;
}
.aboutSkills{
    margin: 5px 10px 0 10px;
    height: 100px;
}
.skillsContainer{
    width: 60%;
}

.mainPage{
    min-height: 78vh;
}
.content{
    width: 70%;
    background-color: var(--bars);
    padding: 10px;
    border-radius: 20px;
}

.figure{
    margin: 2%;
    width: 650px;
    height: 325px;
    background-color: var(--bars);
    padding: 10px;
    border-radius: 20px;
}
.images{
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-position: top;
}
.wipImage{
    object-position: center;
}
.figLinkContainer{
    margin-top: 10px;
}
.btn{
    margin-top: 5px;
}

.resumeFig{
    margin: 2%;
    background-color: var(--bars);
    padding: 10px;
    border-radius: 20px;
}
#download{
    text-decoration: none;
    font-size: x-large;
}

footer{
    margin-top: auto;
    width: 100%;
    min-height: 10vh;
    background-color: var(--bars);
}
.footerLink, .figLink{
    margin: 0 2em;
    color: var(--text);
}
.footerLink:hover, .figLink:hover{
    color: white;
    cursor: pointer;
}